.components--partners--layout--input-container {
  .hint-container {
    color: #9e9e9e;
    font-size: 14px;
  }

  .input-label {
    margin-bottom: 8px;
    font-weight: bold;
  }

  &[data-type="checkbox"] {
    .input-and-label-container {
      display: flex;
    }

    .input-container {
      order: 0;
    }

    .input-label {
      order: 1;
    }
  }
}
