@use "stylesheets/variables" as *;

.component--partners--accounts-subscriptions--changed-notification {
  position: fixed;
  z-index: 3050;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;

  .content-changed-notification {
    max-width: 1050px;
    padding: 20px;
    border: 1px solid #7d4600;
    border-radius: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #0d151c;

    @media (min-width: $md-from) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .icon-container {
    text-align: center;

    @media (max-width: $sm-to) {
      margin-bottom: 10px;
    }

    @media (min-width: $md-from) {
      margin-right: 15px;
    }
  }

  .to-apply-text-container {
    flex-grow: 1;
    margin-right: auto;

    @media (max-width: $sm-to) {
      text-align: center;
    }
  }

  .all-changes-will-take-immediate-effect-container {
    @media (max-width: $sm-to) {
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .total-icon {
    font-size: 28px;

    @media (min-width: $md-from) {
      font-size: 38px;
    }
  }

  .total-container {
    @media (max-width: $sm-to) {
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .amount-container {
    @media (max-width: $sm-to) {
      margin-left: 10px;
    }

    @media (min-width: $md-from) {
      margin-right: 20px;
    }
  }

  .actions-container {
    @media (max-width: $sm-to) {
      text-align: center;
    }

    @media (min-width: $md-from) {
      text-align: right;
    }
  }
}
