.components--front--new-partner--step4--plan-card {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 50px 20px;
  border-radius: 30px;
  background: #fff;
  box-shadow: #0000004a 0 0 20px 0;
  color: #000;

  &[data-current="true"] {
    border: 5px solid #1b9c6e;
    border-radius: 10px 0 10px 10px;
  }

  .plan-current-label {
    position: absolute;
    top: -42px;
    right: -5px;
    padding: 9px 24px;
    border-radius: 5px 5px 0 0;
    background-color: #1b9c6e;
  }

  .plan-badge-label {
    position: absolute;
    top: 29px;
    right: -37px;
    width: 165px;
    padding: 2px;
    background-color: #fdd700;
    color: #fff;
    font-family: fira-sans-condensed;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    transform: rotate(45deg);
  }

  .plan-name {
    margin-bottom: 30px;
    font-family: fira-sans-condensed;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .plan-price {
    display: flex;
    align-items: start;
    justify-content: center;
    margin-bottom: 22px;
    text-align: center;
  }

  .plan-price-amount {
    color: #fdd700;
    font-family: fira-sans-condensed;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
  }

  .price-text {
    margin-top: 8px;
    font-family: fira-sans-condensed;
    font-size: 20px;
    font-weight: 700;
  }

  .plan-description {
    margin-bottom: 17px;
  }

  .plan-choose-button-container {
    display: flex;
    flex-grow: 1;
    align-items: end;
    justify-content: center;
  }

  .choose-plan-button {
    padding: 10px 20px !important; // stylelint-disable-line declaration-no-important
    border-radius: 10px;
    background-color: #fdd700;
    color: #373743 !important; // stylelint-disable-line declaration-no-important
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  &[data-active="true"] {
    border: 5px solid #1b9c6e;

    .choose-plan-button {
      opacity: .5;
    }
  }
}
