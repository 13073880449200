.routes--partners--offers--confirm-price {
  .confirm-price-header {
    margin-bottom: 36px;
    font-size: 47px;
    font-weight: bold;
    text-align: center;
  }

  .you-have-reviewed-the-project-part {
    margin-bottom: 16px;
    font-size: 27px;
    text-align: center;
  }

  .project-part-admin-title {
    max-width: 514px;
    margin-right: auto;
    margin-bottom: 15px;
    margin-left: auto;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  .your-estimated-price-was {
    margin-top: 60px;
    margin-bottom: 12px;
    font-size: 27px;
    text-align: center;
  }

  .offer-description-container {
    max-width: 800px;
    margin: 32px auto 53px;
  }

  .confirm-offer-button {
    display: block;
    margin-right: auto;
    margin-bottom: 17px;
    margin-left: auto;
  }

  .change-offer-button {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .value-header {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .value-container {
    width: 200px;
  }

  .value-body {
    font-size: 20px;
    font-weight: bold;
  }
}
