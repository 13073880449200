@use "stylesheets/variables" as *;

.components--layout--language-select {
  @media (max-width: $sm-to) {
    margin-top: auto;
  }

  @media (min-width: $md-from) {
    margin-left: 35px;
  }

  .locale-link {
    @media (max-width: $sm-to) {
      display: flex;
      width: 80%;
      align-items: center;
      margin-right: auto;
      margin-left: auto;

      &:link,
      &:visited {
        color: #6f6f71;
        text-decoration: none;
      }
    }
  }

  .locale-link + .locale-link {
    @media (max-width: $sm-to) {
      margin-top: 10px;
    }

    @media (min-width: $md-from) {
      margin-left: 10px;
    }
  }

  .locale-label {
    @media (max-width: $sm-to) {
      padding-left: 3px;
    }

    @media (min-width: $md-from) {
      display: none;
    }
  }
}
