.text-green {
  color: #289d8f;
}

.text-right {
  text-align: right !important; // stylelint-disable-line declaration-no-important
}

.text-nowrap {
  white-space: nowrap;
}
