.components--offers--approve-changes {
  .company-information-box {
    padding: 27px;
    border-radius: 5px;
    margin-top: 25px;
    background-color: #eaeaea;
  }

  .we-are-happy-to-send-you-this-offer {
    margin-bottom: 25px;
  }

  .company-info-label {
    font-size: 8px;
  }

  .company-info-value {
    font-size: 12px;
    font-weight: bold;
    text-align: right;
  }
}
