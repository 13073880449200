.components--dynamic-overflow {
  position: relative;
  height: 100%;

  .dynamic-overflow-absolute-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow-y: auto;
  }
}
