@use "stylesheets/variables" as *;

.components-layout-menu {
  box-sizing: border-box;

  @media (max-width: $sm-to) {
    &[data-show="false"] {
      display: none;
    }

    &[data-show="true"] {
      position: fixed;
      z-index: 9;
      top: 0;
      left: 0;
      width: 300px;
      max-width: 100vw;
      height: 100%;
      padding: 70px 20px 20px;
      background: #fff;
      color: #000;
    }
  }

  @media (min-width: $md-from) {
    display: flex;
    align-items: center;
    justify-content: center;

    .menu-language-select {
      display: none;
    }
  }

  .menu-container {
    display: flex;

    @media (max-width: $sm-to) {
      width: 100%;
      height: 100%;
      flex-direction: column;
    }
  }

  .layout-menu-close {
    position: absolute;
    top: 14px;
    right: 20px;
    font-size: 32px;

    &:link,
    &:visited {
      color: #2a9d8f;
    }

    @media (min-width: $md-from) {
      display: none;
    }
  }
}
