.components--nav--item {
  display: inline-block;
  padding: 10px 14px;
  border-radius: 8px;
  margin-right: 12px;
  margin-bottom: 12px;
  background: #abbcd0;

  &:link,
  &:visited {
    color: #fff !important; // stylelint-disable-line declaration-no-important
  }

  &[data-active="true"] {
    background: #d0d5db;
  }
}
