@use "stylesheets/variables" as *;

.components-layout-nav-link {
  position: relative;
  border-color: transparent;

  &:link,
  &:visited {
    text-decoration: none;

    @media (max-width: $sm-to) {
      color: #000;
      font-size: 20px;
    }

    @media (min-width: $md-from) {
      color: #fff;
    }
  }

  &[data-active="true"] {
    @media (min-width: $md-from) {
      &::after {
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 100%;
        border-bottom: 3px solid #289d8f;
        content: "";
      }
    }
  }

  @media (max-width: $sm-to) {
    display: block;
  }
}

.components-layout-nav-link + .components-layout-nav-link {
  @media (max-width: $sm-to) {
    margin-top: 20px;
  }

  @media (min-width: $md-from) {
    margin-left: 10px;
  }
}
