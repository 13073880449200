@use "stylesheets/variables" as *;

.components--layout--footer {
  .get-success-footer {
    padding-top: 85px;
    padding-bottom: 85px;
    background: #1d212b;
  }

  .get-success-footer-content {
    display: flex;
    justify-content: center;
  }

  .get-success-header {
    font-size: 64px;
    font-weight: 800;
    text-align: center;

    @media (min-width: $md-from) {
      width: 600px;
    }
  }

  .get-success-icon-divider {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #289d8f;
    font-size: 40px;
  }

  .get-success-description {
    margin-right: auto;
    margin-left: auto;
    font-size: 20px;
    font-weight: 300;
    text-align: center;

    @media (min-width: $md-from) {
      max-width: 800px;
    }
  }

  .actual-footer {
    padding-top: 50px;
    padding-bottom: 50px;
    background: #274653;
  }

  .actual-footer-link {
    &:link,
    &:visited {
      color: #a7b2b9;
      text-decoration: none;
    }
  }
}
