@use "stylesheets/variables" as *;

.routes--welcome {
  .header-bullet-points {
    @media (min-width: $lg-from) {
      margin-bottom: 140px;
    }
  }

  .header-content-non-binding-offer-button {
    @media (max-width: $md-to) {
      width: 100%;
      margin-top: 60px;
      margin-bottom: 140px;
    }

    @media (min-width: $lg-from) {
      display: none;
    }
  }
}
