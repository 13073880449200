.routes--partners--offers--changed-price {
  .big-green-check-icon {
    display: flex;
    width: 239px;
    height: 239px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 148px auto 75px;
    background-color: #1b9c6e;
    color: #fff;
    font-size: 160px;
  }

  .your-offer-was-updated {
    max-width: 540px;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    font-size: 27px;
    text-align: center;
  }

  .we-will-notify-you {
    max-width: 580px;
    margin-right: auto;
    margin-left: auto;
    font-size: 27px;
    text-align: center;
  }
}
