.components--partners--layout--input {
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  background: #fff;

  .partners-actual-input {
    padding: 19px;
    border: 0;
    background: transparent;
    font-weight: bold;
  }

  &[data-type="date"],
  &[data-type="datetime-local"],
  &[data-type="money"] {
    .partners-actual-input {
      text-align: right;
    }
  }

  .money-currency {
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 1px solid #d3d3d3;
    background: transparent;
    font-weight: bold;
  }
}
