.components--layout--button {
  display: inline-block;
  padding: 10px 15px;
  background: #fff;
  font-size: 16px;
  text-align: center;

  &:link,
  &:visited {
    text-decoration: none;
  }

  &[data-blue="true"] {
    background: #2e4552;
    color: #fff;
  }

  &[data-green="true"] {
    background: #06d169;
    white-space: nowrap;

    &:link,
    &:visited {
      color: #fff;
      text-decoration: none;
    }
  }

  &[data-green="false"] {
    border: 1px solid #000;
  }

  &[data-large="true"] {
    padding: 15px 60px;
  }

  &[data-partners="true"] {
    padding: 7px 19px;
    border: 0 !important; // stylelint-disable-line declaration-no-important
    border-radius: 6px;
    background-color: #fdd700;
    white-space: nowrap;

    &:link,
    &:visited {
      color: #000 !important; // stylelint-disable-line declaration-no-important
    }
  }

  &[data-primary="true"] {
    border: 0;
    background: #1b9c6e;
    color: #fff !important; // stylelint-disable-line declaration-no-important
  }

  &[data-uppercase="true"] {
    text-transform: uppercase;
  }
}
