@use "stylesheets/variables" as *;

.components-box-points-box-point {
  position: relative;
  background: #fff;
  color: #000;

  @media (max-width: $sm-to) {
    padding: 65px 30px;
  }

  @media (min-width: $md-from) {
    padding: 65px 30px 30px;
  }

  .box-point-number {
    position: absolute;
    top: -45px;
    right: 0;
    left: 0;
    display: flex;
    width: 90px;
    height: 90px;
    align-items: center;
    border-radius: 50%;
    margin-right: auto;
    margin-left: auto;
    background: #289d8f;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
  }

  .box-point-label {
    font-size: 24px;
    font-weight: bold;
  }

  .box-point-icon {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #818a91;
    font-size: 36px;

    .fa {
      -webkit-text-stroke: 2px;
      text-stroke: 2px; // stylelint-disable-line property-no-unknown
    }
  }

  .box-point-bottom-icon {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    color: #289d8f;
    font-size: 28px;
  }
}
