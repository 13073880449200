.routes--new-partner--step6 {
  .you-are-created-as-a-partner-now-container {
    margin-bottom: 68px;
    font-size: 40px;
  }

  .check-icon {
    display: flex;
    width: 239px;
    height: 239px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #1b9c6e;
    font-size: 150px;
  }

  .you-are-now-ready-to-bid-container {
    margin-top: 46px;
    margin-bottom: 26px;
    font-size: 40px;
    text-align: center;
  }

  .go-to-start-page-button {
    padding: 30px 97px;
    font-size: 36px;
  }
}
