@use "stylesheets/variables" as *;

.components-box-points {
  @media (max-width: $sm-to) {
    .components-box-points-box-point + .components-box-points-box-point {
      margin-top: 4px;
    }
  }

  @media (min-width: $md-from) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .components-box-points-box-point + .components-box-points-box-point {
      margin-left: 15px;
    }
  }

  @media (min-width: $lg-from) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
