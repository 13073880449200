@use "stylesheets/variables" as *;

.components--card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 57px 0 rgb(0 0 0 / 15%);

  &[data-has-footer="true"] {
    border-radius: 5px;
    background: #274653;
  }

  .card-inner {
    flex-grow: 1;
    background: #fff;
    background-size: cover;
    color: #333;
  }

  .card-header {
    padding-bottom: 20px;
    font-size: 36px;

    @media (min-width: $sm-from) {
      padding: 15px 15px 0;
    }

    @media (min-width: $md-from) {
      padding: 25px 25px 0;
    }

    @media (min-width: $lg-from) {
      padding: 35px 35px 0;
    }
  }

  .card-body {
    padding: 8px;

    @media (min-width: $sm-from) {
      padding: 15px;
    }

    @media (min-width: $md-from) {
      padding: 25px;
    }

    @media (min-width: $lg-from) {
      padding: 35px;
    }
  }

  .card-footer {
    padding: 30px;
  }
}

.component-bootstrap-card .card-header {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: bold;
}
