.routes--partners--offers--confirmed-price {
  .big-green-check-icon {
    display: flex;
    width: 239px;
    height: 239px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: 70px;
    margin-bottom: 70px;
    background-color: #1b9c6e;
    color: #fff;
    font-size: 100px;
  }
}
