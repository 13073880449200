@use "stylesheets/variables" as *;

.components--offers--presentation {
  .company-attribute-key {
    font-size: 8px;
  }

  .company-attribute-value {
    font-size: 12px;
    font-weight: bold;
    text-align: right;
  }

  .offer-presentation-main-column {
    width: 500px; // Setting height and flex-grow makes it maximize without forcing shrink on the side column - dont remove this!
    flex-grow: 1;
    padding-right: 41px;
    border-right: 1px solid #ccc;
  }

  .offer-presentation-side-column {
    width: 302px;
    padding-left: 42px;
  }

  .partner-attributes-card {
    margin-bottom: 25px;
  }

  .partner-profile-picture {
    border: 1px solid #000;
    border-radius: 3px;
    margin-bottom: 25px;
    float: right;

    @media (max-width: $sm-to) {
      width: 100%;
    }

    @media (min-width: $md-from) {
      max-width: 100%;
      margin-left: 25px;
    }
  }
}
