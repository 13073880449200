@use "stylesheets/variables" as *;

.routes--new-partner--step2 {
  .form-container {
    width: 100%;

    @media (min-width: $md-from) {
      width: 340px;
    }
  }
}
