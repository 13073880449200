.routes--partners--offers--accept-commission {
  .congratulations {
    margin-bottom: 25px;
    font-size: 47px;
    text-align: center;
  }

  .accepted-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 79px;
  }

  .big-green-check-icon {
    display: flex;
    width: 239px;
    height: 239px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: 148px;
    margin-right: auto;
    margin-left: auto;
    background: #1b9c6e;
    color: #fff;
    font-size: 140px;
  }

  .green-check-icon {
    display: flex;
    width: 63px;
    height: 63px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 20px;
    background: #1b9c6e;
    color: #fff;
    font-size: 42px;
  }

  .accepted-text {
    font-size: 24px;
  }

  .reserved-commission-failed-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .doesnt-it-work-headline {
    font-size: 47px;
    text-align: center;
  }

  .doesnt-it-work-text {
    max-width: 690px;
    margin: 10px auto 30px;
  }

  .red-failed-icon {
    display: flex;
    width: 63px;
    height: 63px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 20px;
    background-color: #bf1616;
    color: #fff;
    font-size: 42px;
  }

  .you-have-won-the-project-part {
    margin-bottom: 11px;
    font-size: 27px;
    text-align: center;
  }

  .project-part-admin-title {
    margin-bottom: 88px;
    font-size: 20px;
    text-align: center;
  }

  .prices-container {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  .price-container {
    width: 200px;
  }

  .price-header {
    font-size: 11px;
  }

  .price-value {
    font-size: 14px;
    font-weight: bold;
  }

  .please-accept-reservation-of-commission {
    margin-bottom: 25px;
    font-size: 16px;
    text-align: center;
  }

  .when-you-have-accepted {
    margin-bottom: 25px;
    font-size: 16px;
    text-align: center;
  }

  .when-you-have-accepted-price {
    margin-bottom: 42px;
    font-size: 16px;
    text-align: center;
  }

  .accept-capture-button-container {
    text-align: center;
  }
}
