@use "stylesheets/variables" as *;

[data-class*="components--plans--choose-modal"] {
  .choose-plan-header-container {
    margin-bottom: 20px;
    font-size: 27px;
    text-align: center;
  }

  .choose-plan-text-container {
    text-align: center;
  }

  .confirm-which-plan {
    font-size: 27px;
    text-align: center;
  }

  .coupon-code-input {
    max-width: 138px;
  }

  .you-already-have-a-plan-but {
    margin-top: 21px;
    text-align: center;
  }

  .offers-count-this-month-exceeds-subscription {
    margin-bottom: 28px;
    font-size: 27px;
    text-align: center;
  }

  .plans-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 27px;
    margin-bottom: 67px;
  }

  .plan-card-container {
    width: 100%;
    padding: 10px;
    margin-right: 15px;
    margin-left: 15px;

    @media (min-width: $lg-from) {
      width: 350px;
    }
  }

  .success-check-icon {
    display: flex;
    width: 239px;
    height: 239px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #1b9c6e;
    color: #fff;
    font-size: 40px;
  }
}
