.components--admin--users--sms-messages--sms-message {
  .author-container {
    font-size: 14px;
  }

  .date-container {
    color: #9ea1a4;
    font-size: 10px;
  }

  .message-container {
    width: 80%;
    padding: 10px;
    border-radius: 8px;
  }

  &[data-from-gbt="true"] {
    .author-container {
      order: 2;
      padding-right: 8px;
      text-align: right;
    }

    .date-container {
      order: 1;
      margin-right: 5px;
      margin-left: auto;
    }

    .message-container {
      margin-left: 20%;
      text-align: right;
    }
  }

  &[data-from-gbt="false"] {
    .author-container {
      order: 1;
      padding-left: 8px;
    }

    .date-container {
      order: 2;
      margin-right: auto;
      margin-left: 5px;
    }

    .message-container {
      margin-right: 20%;
    }
  }

  &[data-from-gbt="true"][data-has-error="false"] .message-container {
    background: #09f;
    color: #fff;
  }

  &[data-from-gbt="false"][data-has-error="false"] .message-container {
    background: #f1f0f0;
    color: #000;
  }

  &[data-has-error="true"] .message-container { // stylelint-disable-line no-descending-specificity
    background: #d82020;
    color: #fff;
  }

  + .components--admin--users--sms-messages--sms-message {
    margin-top: 15px;
  }
}
