@use "stylesheets/variables" as *;

.components--partners--layout--upgrade-bar {
  width: 100%;
  padding: 11px;
  background-color: #bf1616;
  cursor: pointer;
  text-align: center;

  @media (min-width: $md-from) {
    position: fixed;
    z-index: 9999;
  }
}
