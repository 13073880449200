@use "api-maker-bootstrap-card";
@use "api-maker-inputs-money";
@use "api-maker-pagination";
@use "grid";
@use "invalid-feedback";
@use "margins";
@use "tables";
@use "texts";
@use "widths";
@use "fira-sans-condensed-font";
@use "variables";

html,
body,
.react-root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: barlow, sans-serif;
  font-size: 17px;
  font-weight: 400;
}

input {
  font-size: 17px;
}

body {
  background: #1d212b;
  color: #fff;

  @media (max-width: variables.$sm-to) {
    &:has(.components--modal) {
      overflow: hidden;
      height: 100vh;
    }
  }
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
