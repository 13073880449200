.component-block-ui {
  &[data-blocking="true"] {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-top: 35vh;
    background: rgb(0 0 0 / 80%);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  &[data-blocking="false"] {
    display: none;
  }
}
