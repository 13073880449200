.components-layout-select {
  display: block;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 0;
  font-size: 17px;

  &:focus {
    outline: none;
  }
}
