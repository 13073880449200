.components--file-attachments--preview {
  position: relative;

  .video-play-icon-container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .video-play-icon-background {
    z-index: 9;
    display: flex;
    width: 33px;
    height: 33px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    background: rgba(0 0 0 / 50%);
  }

  .video-play-icon {
    color: #fff;
  }
}
