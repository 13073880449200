.components--offers--card {
  background: #fff;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);

  .offer-card-header {
    height: 100px;
    padding: 18px;
    background-position: center center;
    background-size: cover;
  }

  .offer-card-project-part-admin-title {
    color: #fff;
    font-size: 26px;
  }

  .offer-card-offer-with-number {
    margin-top: 10px;
    color: #ddd;
    font-size: 20px;
  }

  .offer-card-button-container {
    padding: 16px;
    background: #3e4859;
  }

  .offer-card-choose-offer-button {
    display: block;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 8px;
    background: #e6b55d;
    font-weight: bold;
    text-align: center;

    &:link,
    &:visited {
      color: #747474;
    }
  }

  .offer-card-detail {
    display: flex;
    width: 100%;
    padding: 12px;

    + .offer-card-detail {
      border-top: 1px solid #d7d7d7;
    }

    .offer-card-detail-label {
      font-weight: 600;
      text-transform: uppercase;
    }

    .offer-card-detail-value {
      margin-left: auto;
    }
  }
}
