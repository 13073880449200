.components--admin--layout--input-container {
  &[data-type="checkbox"] {
    .input-and-label-container {
      display: flex;
    }

    .input-container {
      order: 0;
    }

    .input-label {
      order: 1;
    }
  }

  .hint-container {
    color: #9e9e9e;
    font-size: 14px;
  }
}
