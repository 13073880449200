@use "stylesheets/variables" as *;

.routes--new-partner--step4 {
  .plans-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 59px;
    margin-bottom: 20px;
  }

  .plan-card-container {
    width: 100%;
    max-width: 350px;
    padding: 10px;

    @media (min-width: $lg-from) {
      width: 50%;
    }

    @media (min-width: $xl-from) {
      width: 33%;
    }
  }
}
