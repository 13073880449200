.routes--partners--accounts-subscriptions--index {
  .plan-quantity-input {
    width: 100%;
    appearance: none;
    background: transparent;
    outline: none;
  }

  .plan-quantity-input::-webkit-slider-runnable-track {
    height: 8px;
    border-radius: 5px;
    background: #202f3b;
  }

  .plan-quantity-input::-webkit-slider-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    border-radius: 50%;
    margin-top: -3px;
    appearance: none;
    background: #ee9625;
    cursor: pointer;
  }

  .plan-quantity-input::-moz-range-track {
    height: 8px;
    border-radius: 5px;
    background: #202f3b;
  }

  .plan-quantity-input::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    border-radius: 50%;
    margin-top: -3px;
    appearance: none;
    background: #202f3b;
    cursor: pointer;
  }

  .plan-quantity-input::-ms-thumb {
    width: 9px;
    height: 9px;
    border: 6px solid #fff;
    border-radius: 50%;
    appearance: none;
    background: #202f3b;
    cursor: pointer;
  }

  .plan-quantity-input::-ms-track {
    border-color: transparent;
    border-radius: 5px;
    appearance: none;
    background: transparent;
    color: transparent;
    outline: none;
  }

  .plan-quantity-input::-ms-fill-upper,
  .plan-quantity-input::-ms-fill-lower {
    border-radius: 5px;
    background: #202f3b;
  }
}
