.components--gbt-layout--button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;

  &:link,
  &:visited {
    text-decoration: none;
  }

  .gbt-layout-button-icon {
    margin-right: 7px;
  }

  &[data-color="grey"] {
    border: 1px solid #595959;
    background-color: #595959;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: #373743;
    }
  }

  &[data-color="yellow"] {
    border: 1px solid #fdd700;
    background-color: #fdd700;
    color: #373743;

    &:hover {
      background-color: #fff;
    }
  }
}
