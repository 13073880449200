@font-face {
  font-family: fira-sans-condensed;
  src: url("fonts/Fira_Sans_Condensed/FiraSansCondensed-Regular.ttf");
}

@font-face {
  font-family: fira-sans-condensed;
  font-weight: bold;
  src: url("fonts/Fira_Sans_Condensed/FiraSansCondensed-Bold.ttf");
}
