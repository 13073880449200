.components--layout--input {
  display: block;
  width: 100%;
  padding: 14px 20px;
  border: 1px solid #ddd;
  border-radius: 0;
  font-size: 17px;

  &:focus {
    outline: none;
  }

  &[data-resize="vertical"] {
    resize: vertical;
  }
}
