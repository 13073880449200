.components--gbt-layout--header {
  display: flex;
  align-items: center;
  padding: 5px;
  background: #fff;
  color: #000;

  .gbt-layout-logo {
    width: 353px;
    margin-right: auto;
    margin-left: 60px;
  }

  .header-link {
    padding-bottom: 9px;
    font-family: fira-sans-condensed;
    font-size: 21px;
    font-weight: bold;

    &[data-active="true"] {
      border-bottom: 3px solid #fdd700;
    }

    &:link,
    &:visited {
      color: #373743;
      text-decoration: none;
    }

    + .header-link {
      margin-left: 69px;
    }
  }

  .call-phone-button {
    margin-right: 60px;
    margin-left: 20px;
    font-family: fira-sans-condensed;
  }

  .sign-in-button {
    margin-left: 40px;
    font-family: fira-sans-condensed;
  }
}
