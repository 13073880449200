.components--popup-menu {
  position: absolute;
  top: 12px;
  left: 12px;
  min-width: 230px;
  padding: 12px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
  font-size: 14px;
}

.components--popup-menu--menu-item {
  &:link,
  &:visited {
    color: #685c5f;
  }
}
