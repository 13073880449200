.routes--new-partner--step5 {
  .credit-card-image {
    width: 54px;

    + .credit-card-image {
      margin-left: 5px;
    }
  }

  .credit-card-images-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .credit-card-form-container {
    width: 340px;
  }

  .submit-card-button {
    width: 100%;
  }
}
