.components--offers--table {
  .offer-state[data-partners="true"] {
    display: inline-block;
    padding: 6px 9px;
    border-radius: 5px;
    font-size: 14px;

    .success-icon {
      display: none;
    }

    &[data-state="binding_agreement"] {
      background-color: #28a745;
      color: #fff;

      .success-icon {
        display: block;
      }
    }

    &[data-state="new"],
    &[data-state="pending_customer_acceptance"],
    &[data-state="accepted_by_admin"],
    &[data-state="pending_customer_review_offer_changes"],
    &[data-state="pending_partner_review"],
    &[data-state="accepted_by_customer"] {
      background-color: #ffc107;
      color: #212529;
    }

    &[data-state="rejected_by_admin"],
    &[data-state="customer_rejected_offer_changes"],
    &[data-state="rejected_by_customer"] {
      background-color: #dc3545;
      color: #fff;
    }
  }
}
