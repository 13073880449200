.bullet-point {
  display: flex;
  align-items: center;

  .bullet-point-icon {
    display: flex;
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 8px;
    background: #289d8f;
    color: #26354b;

    .fa {
      -webkit-text-stroke: 2px;
      text-stroke: 2px; // stylelint-disable-line property-no-unknown
    }
  }

  .bullet-point-content {
    font-size: 24px;
  }
}

.bullet-point + .bullet-point {
  margin-top: 10px;
}
