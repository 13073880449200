@use "stylesheets/variables" as *;

.components-layout {
  .layout-container {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: $lg-to) {
      padding-right: 12px;
      padding-left: 12px;
    }

    @media (min-width: $xl-from) {
      width: 1100px;
    }
  }

  .layout-language-select {
    display: flex;
    flex: 1;
    justify-content: end;

    @media (max-width: $sm-to) {
      display: none;
    }
  }

  .layout-content {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .layout-header-title {
    font-size: 40px;

    @media (min-width: $md-from) {
      margin-top: 140px;
    }
  }

  .layout-menu {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $sm-to) {
      padding-top: 15px;
    }

    @media (min-width: $md-from) {
      padding-top: 30px;
    }
  }

  .layout-menu-toggle {
    margin-right: 15px;
    margin-left: auto;
    font-size: 28px;

    &:link,
    &:visited {
      color: #fff;
    }

    @media (min-width: $md-from) {
      display: none;
    }
  }

  .layout-menu-header {
    flex: 1;
  }

  .layout-menu-header-link {
    overflow: none;

    &:link,
    &:visited {
      color: #fff;
      font-size: 36px;
      text-decoration: none;
    }
  }

  .layout-menu-call-to-action-button {
    padding: 12px 24px;
  }

  a:link,
  a:visited {
    color: #a7b2b9;
    text-decoration: none;
  }

  a:active,
  a:hover {
    color: #fff;
  }
}
