.components--message-threads--messages--message {
  .message-body {
    flex-grow: 1;
    padding: 12px;
    border-radius: 8px;
  }

  &[data-self="true"] {
    margin-left: 20%;

    .message-body {
      background: #c2beff;
    }
  }

  &[data-self="false"] {
    margin-right: 20%;

    .message-body {
      background: #f9f9f9;
    }
  }

  + .components--message-threads--messages--message {
    margin-top: 12px;
  }

  .message-and-author-image {
    display: flex;
    align-items: end;
  }

  .message-and-author-name {
    width: 100%;
  }

  .author-image {
    width: 50px;
    height: 50px;
    border: 1px solid #ececec;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
  }

  .author-image + .message-and-author-name,
  .message-and-author-name + .author-image {
    margin-left: 5px;
  }
}
