@use "stylesheets/variables" as *;

.components--app-layout--header {
  top: 0;
  display: flex;
  height: 100px;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  background: #274653;
  color: #282a33;

  @media (max-width: $sm-to) {
    width: 100%;

    .header-actions-container {
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;

      display: flex;
      width: 100vw;
      height: 100vh;
      align-items: center;
      justify-content: center;

      background: rgba(#000, .8);

      &[data-active="false"] {
        display: none;
      }
    }

    .header-actions {
      min-width: 80vw;
      max-width: 100vw;
      background: #fff;

      .action-button {
        display: block;
        width: 100%;
        padding: 11px;
        border: 0;
        text-align: left;

        .fa {
          margin-right: 5px;
        }

        + .action-button {
          border-top: 1px solid #c9c9c9;
        }

        &:link,
        &:visited {
          color: #000;
        }

        &:active,
        &:hover {
          background: #dddcf0;
        }
      }
    }
  }

  @media (min-width: $md-from) {
    position: fixed;
    z-index: 1;
    left: 250px;
    width: calc(100% - 250px);

    .header-actions-container[data-has-header-right="false"] {
      margin-left: auto;
    }

    .header-actions {
      display: flex;
      align-items: center;
      margin-left: auto;

      .action-button {
        display: inline-block;
        padding: 7px 10px;
        border: 1px solid #cbd5e1;
        border-radius: 5px;
        margin-right: 4px;
        margin-bottom: 4px;
        background: #fff;
        font-size: 13px;

        &:link,
        &:visited {
          color: #000;
        }
      }
    }
  }

  @media (min-width: $lg-from) {
    left: 280px;
    width: calc(100% - 280px);
  }

  .burger-menu-container {
    @media (max-width: $sm-to) {
      display: flex;
      align-items: center;
      font-size: 28px;

      &[data-has-header-right="true"] {
        margin-left: 10px;
      }

      &[data-has-header-right="false"] {
        margin-left: auto;
      }
    }

    @media (min-width: $md-from) {
      display: none;
    }
  }

  .actions-link {
    margin-right: 8px;
    font-size: 22px;
  }

  .actions-link,
  .burger-menu-link {
    &:link,
    &:visited {
      color: #fff;
    }
  }

  .header-right {
    margin-left: auto;
  }

  .header-title-container {
    color: #fff;
    font-size: 22px;
  }
}
