@use "variables";

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-sm-2 {
  @media (min-width: variables.$sm-from) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.grid-cols-md-1 {
  @media (min-width: variables.$md-from) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.grid-cols-lg-2 {
  @media (min-width: variables.$lg-from) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.grid-cols-lg-3 {
  @media (min-width: variables.$lg-from) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.grid-cols-xl-3 {
  @media (min-width: variables.$xl-from) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.grid-gap-10 {
  grid-gap: 10px;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-md-1 {
  @media (min-width: variables.$md-from) {
    grid-column: span 1 / span 1;
  }
}

.col-span-md-2 {
  @media (min-width: variables.$md-from) {
    grid-column: span 2 / span 2;
  }
}

.row-span-md-2 {
  @media (min-width: variables.$md-from) {
    grid-row: span 2 / span 2;
  }
}

.row-span-md-3 {
  @media (min-width: variables.$md-from) {
    grid-row: span 3 / span 3;
  }
}

.col-span-lg-1 {
  @media (min-width: variables.$lg-from) {
    grid-column: span 1 / span 1;
  }
}

.col-span-lg-2 {
  @media (min-width: variables.$lg-from) {
    grid-column: span 2 / span 2;
  }
}

.col-span-lg-3 {
  @media (min-width: variables.$lg-from) {
    grid-column: span 3 / span 3;
  }
}

.col-span-xl-1 {
  @media (min-width: variables.$xl-from) {
    grid-column: span 1 / span 1;
  }
}

.col-span-xl-2 {
  @media (min-width: variables.$xl-from) {
    grid-column: span 2 / span 2;
  }
}

.col-span-xl-3 {
  @media (min-width: variables.$xl-from) {
    grid-column: span 3 / span 3;
  }
}
