@use "stylesheets/variables" as *;

[data-components--modal="true"] {
  > .modal-card {
    position: relative;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    &[data-scrollable="true"] {
      height: 100%;
    }

    @media (max-width: $sm-to) {
      width: 100vw;
      height: 100vh;
    }

    @media (min-width: $md-from) {
      max-width: calc(100% - 100px);
      max-height: calc(100% - 100px);
      border-radius: 5px;
      margin-top: 50px;
    }

    @media (min-width: $lg-from) {
      max-width: 800px;
    }

    @media (min-width: $xl-from) {
      max-width: 1100px;
    }

    @media (min-width: $xxl-from) {
      max-width: 1300px;
    }

    .card-body {
      background-color: #e8ebe4;
    }

    > .card-inner {
      @media (min-width: $md-from) {
        border-radius: 5px;
      }

      > .components--dynamic-overflow .dynamic-overflow-absolute-container { // stylelint-disable-line selector-max-compound-selectors
        @media (min-width: $md-from) {
          max-height: calc(100vh - 100px);
          border-radius: 5px;

          &::-webkit-scrollbar { // stylelint-disable-line max-nesting-depth, selector-max-compound-selectors
            width: 13px;
            height: 13px;
          }

          &::-webkit-scrollbar-track { // stylelint-disable-line max-nesting-depth, selector-max-compound-selectors
            border-radius: 5px;
            box-shadow: inset 0 0 6px rgba(0 0 0 / 30%);
          }

          &::-webkit-scrollbar-thumb { // stylelint-disable-line max-nesting-depth, selector-max-compound-selectors
            border-radius: 5px;
            box-shadow: inset 0 0 6px rgba(0 0 0 / 50%);
          }
        }
      }
    }
  }
}
