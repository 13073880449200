.components--app-layout--menu--menu-item {
  display: flex;
  width: 80%;
  align-items: center;
  padding: 10px 14px;
  margin-right: auto;
  margin-left: auto;
  text-decoration: none;

  &:link,
  &:visited {
    color: #6f6f71 !important; // stylelint-disable-line declaration-no-important
  }

  &[data-active="true"],
  &:hover {
    border-radius: 7px;
    background: #323435;
    color: #b9b9bb !important; // stylelint-disable-line declaration-no-important
  }

  .menu-item-icon {
    margin-right: 4px;
    font-size: 12px;
  }

  + .components--app-layout--menu--menu-item {
    margin-top: 6px;
  }
}
