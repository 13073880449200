@font-face {
  font-family: inter;
  src: url("fonts/Inter/Inter-VariableFont_slnt,wght.ttf");
}

.components--gbt-layout {
  width: 100%;
  min-height: 100%;
  background-color: #adaeae;
  color: #000;
  font-family: inter;

  > .children-container {
    padding: 50px;
  }
}
